<template>
  <nav
    class="h-[60px] bg-white dark:bg-[#232323] fixed w-full flex justify-center z-50 border-b border-[#E4F6F8] dark:border-cs-black"
    data-aos="fade-down"
  >
    <div class="flex items-center h-full justify-between w-full md:w-[768px] lg:w-[1440px] px-[30px]">
      <NuxtLink to="/landing">
        <CardanoSpotLogo class="dark:hidden" />
        <CardanoSpotLogoLight class="hidden dark:block" />
      </NuxtLink>
      <div class="justify-center items-center hidden lg:flex">
<!--        <NuxtLink class="flex items-center mr-[50px]" to="/community-feed/all">-->
<!--          <IconRss class="mr-1 dark:[&>*]:stroke-white" />-->
<!--          <span class="text-cs-black dark:text-white text-sm leading-6">{{ $t('header.community') }}</span>-->
<!--        </NuxtLink>-->
        <NuxtLink class="flex items-center mr-[50px]" to="/news-feed">
          <IconNews class="mr-1 dark:[&>*]:stroke-white" />
          <span class="text-cs-black dark:text-white text-sm leading-6">{{ $t('header.newsArticles') }}</span>
        </NuxtLink>
<!--        <NuxtLink class="flex items-center mr-[50px]" to="/project-library/all">-->
<!--          <IconProjects class="mr-1 dark:[&>*]:stroke-white" />-->
<!--          <span class="text-cs-black dark:text-white text-sm leading-6">{{ $t('header.projects') }}</span>-->
<!--        </NuxtLink>-->
<!--        <NuxtLink class="flex items-center mr-[50px]" to="/events">-->
<!--          <IconEvents class="mr-1 dark:[&>*]:stroke-white" />-->
<!--          <span class="text-cs-black dark:text-white text-sm leading-6">{{ $t('header.events') }}</span>-->
<!--        </NuxtLink>-->
<!--        <NuxtLink class="flex items-center mr-[50px]" to="/market-status">-->
<!--          <IconChart class="mr-1 dark:[&>*]:stroke-white" />-->
<!--          <span class="text-cs-black dark:text-white text-sm leading-6">{{ $t('header.marketStatus') }}</span>-->
<!--        </NuxtLink>-->
<!--        <NuxtLink class="flex items-center" to="/wiki/vocabulary/a">-->
<!--          <IconWiki class="mr-1 dark:[&>*]:stroke-white" />-->
<!--          <span class="text-cs-black dark:text-white text-sm leading-6">{{ $t('header.wiki') }}</span>-->
<!--        </NuxtLink>-->
      </div>
      <div class="flex justify-between items-center">
        <DarkThemeSwitcher class="hidden md:flex" />
        <LanguageSwitcher class="mx-[30px]" />
        <Button
          :on-click="onSignInClick"
          :text="$t('header.login')"
          class="rounded-[50px] !px-5 !text-sm !py-[10px] hidden md:block"
          variant="light"
        />
        <IconUser class="md:hidden dark:[&>*]:stroke-white" @click="onSignInClick" />
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import IconRss from 'assets/icons/landing/icon-rss.svg'
import IconNews from 'assets/icons/landing/icon-news.svg'
import IconProjects from 'assets/icons/landing/icon-box.svg'
import IconEvents from 'assets/icons/landing/icon-calendar.svg'
import IconChart from 'assets/icons/landing/icon-chart.svg'
import IconWiki from 'assets/icons/landing/icon-wiki.svg'
import IconUser from 'assets/icons/landing/user.svg'
import LanguageSwitcher from '~/components/features/other/LanguageSwitcher.vue'

const onSignInClick = () => navigateTo('/user/login')
</script>
